import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor() {}

  private readonly EMAIL_KEY = 'auth_email';

  setEmail(email: string): void {
    localStorage.setItem(this.EMAIL_KEY, email);
  }

  setUserType(userType: string): void {
    localStorage.setItem('user_type', userType);
  }

  getEmail(): string | null {
    return localStorage.getItem(this.EMAIL_KEY);
  }

  getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  setToken(token: string): void {
    localStorage.setItem('access_token', token);
  }

  removeToken(): void {
    console.log("TOKEN REMOVED")
    localStorage.removeItem('access_token');
    localStorage.removeItem(this.EMAIL_KEY);
  }

  isValidToken(): boolean {
    const token = this.getToken();
    if (!token) return false;

    const payload = JSON.parse(atob(token.split('.')[1]));

    return Date.now() < payload.exp * 1000;
  }
}

import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request is for the login or register endpoints



    if (req.url.endsWith('/login') || req.url.endsWith('/register'))  {
      // If it is, bypass the interceptor and forward the request
      return next.handle(req);
    }

    // Otherwise, get the auth token from localStorage
    const authToken = localStorage.getItem('access_token');


    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`)
    });

    // Send the cloned request with the header to the next handler.
    return next.handle(authReq);
  }
}

@if(pageInfo?.['title'] != 'Analytical' && pageInfo?.['title'] != 'eCommerce'){
  <div class="bg-light-primary rounded p-y-30 p-x-24 m-b-30 overflow-hidden">
    <div class="row">
      <div class="col-sm-8">
        <h4 class="page-title m-0 f-s-20 f-w-600 m-b-16">
          {{ pageInfo?.['title'] }}
        </h4>
        <div class="d-flex align-items-center overflow-hidden">
          <ul class="breadcrumb">
            @for (url of pageInfo?.['urls']; track url.url; let index = $index, last = $last) { 
              @if(!last){
                <li class="breadcrumb-item" [routerLink]="url.url">
                  <a [routerLink]="url.url">{{ url.title }}</a>
                </li>
              } @else {
                <li class="breadcrumb-item">
                  <i-tabler name="circle-filled" class="icon-8"></i-tabler>
                </li>
                <li class="breadcrumb-item active">{{ url.title }}</li>
              }
            }
          </ul>
        </div>  
      </div>
      <div class="col-sm-4 text-right position-relative">
        <div class="breadcrumb-icon">
          <!-- Conditionally show the image only on the 'Naloge' page -->
          <img
            src="/assets/images/breadcrumb/lawfrendo-user.png"
            alt="breadcrumb"
            width="125"
          />
        </div>
      </div>
    </div>
  </div>
}
<mat-toolbar class="topbar">
  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  @if(showToggle) {
  <button
    mat-icon-button
    (click)="toggleCollapsed.emit()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  }

  <!-- Mobile Menu -->
  @if(!showToggle) {
  <button
    mat-icon-button
    (click)="toggleMobileNav.emit()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  }
  <!-- --------------------------------------------------------------- -->

  <!-- --------------------------------------------------------------- -->
  <!--  Search -->
  <!-- --------------------------------------------------------------- -->
  <button
    mat-icon-button
    (click)="openDialog()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="search" class="icon-20 d-flex"></i-tabler>
  </button>



  <span class="flex-1-auto"></span>



  <!-- --------------------------------------------------------------- -->
  <!-- langugage Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!--<button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
    <img
      [src]="selectedLanguage.icon"
      class=" object-cover icon-20"
    />
  </button>
  <mat-menu #flags="matMenu" class="cardWithShadow">
    @for(lang of languages; track lang.icon) {
    <button mat-menu-item (click)="changeLanguage(lang)">
      <div class="d-flex align-items-center">
        <img
          [src]="lang.icon"
          class="object-cover m-r-8 icon-20"
        />
        <span class="mat-subtitle-1 f-s-14">{{ lang.language }}</span>
      </div>
    </button>
    }
  </mat-menu>-->

  <!-- --------------------------------------------------------------- -->
  <!-- Notification Dropdown -->
  <!-- --------------------------------------------------------------- -->

  <mat-menu #notificationmenu="matMenu" class="topbar-dd cardWithShadow">
    <div class="d-flex align-items-center p-x-32 p-y-16">
      <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Notifications</h6>
      <span class="m-l-auto">
        <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12"
          >5 new</span
        >
      </span>
    </div>

    @for(notification of notifications; track notification.title) {
    <button mat-menu-item class="p-x-32 p-y-16">
      <div class="d-flex align-items-center">
        <img [src]="notification.img" class="rounded-circle" width="48" />
        <div class="m-l-16">
          <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
            {{ notification.title }}
          </h5>
          <span>{{ notification.subtitle }}</span>
        </div>
      </div>
    </button>
    }

    <div class="p-y-12 p-x-32">
      <button mat-stroked-button color="primary" class="w-100">
        See all notifications
      </button>
    </div>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button
    mat-icon-button
    [matMenuTriggerFor]="profilemenu"
    aria-label="Notifications"
  >
    <img
      src="/assets/images/profile/user-1.jpg"
      class="rounded-circle object-cover icon-35 profile-dd"
      width="35"
    />
  </button>
  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
    <ng-scrollbar class="position-relative" style="height: 647px">
      <div class="p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Uporabniški profil</h6>

        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <div class="m-l-16">

            <span class="d-flex align-items-center">
              <i-tabler name="mail" class="icon-15 m-r-4"></i-tabler>
              {{email}}
            </span>
          </div>
        </div>
      </div>
      <div class="p-x-32">
        @for(profile of profiledd; track profile.title) {
        <a
          class="p-y-16 text-decoration-none d-block text-hover-primary"
          [routerLink]="[profile.link]"
        >
          <div class="d-flex align-items-center">
            <button
              mat-mini-fab
              class="text-primary bg-light-primary shadow-none rounded"
            >
              <img [src]="profile.img" width="20" />
            </button>

            <div class="m-l-16">
              <h5
                class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text"
              >
                {{ profile.title }}
              </h5>
              <span class="mat-body-1">{{ profile.subtitle }}</span>
            </div>
          </div>
        </a>
        }

        <!-- upgrade -->
      </div>

      <div class="p-y-12 p-x-32">
        <a
          (click)="logout()"   
          mat-stroked-button
          color="primary"
          class="w-100"
          >Izpis</a
        >
      </div>
    </ng-scrollbar>
  </mat-menu>
</mat-toolbar>
